<template>
  <div>
    <div class="modalPopup" id="order_modal">
      <div class="modal_header">
        <h3 class="title">작업지시서 검색</h3>
        <button class="modal_close" @click="$emit('onclose')"></button>
      </div>
      <div class="modal_body">
        <div class="dateset">
          <div class="input_text">
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="start_date"
              @change="changeStartDate($event)"
            />~
          </div>
          <div class="input_text">
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="end_date"
              @change="changeEndDate($event)"
            />
          </div>
        </div>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <colgroup>
              <col v-for="(n, index) in 7" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>작업지시일</th>
                <th>거래처</th>
                <th>LotNo</th>
                <th>제품명</th>
                <th>지시수량</th>
                <th>공정완료 여부</th>
                <th>긴급 여부</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(lot, index) in lots"
                :key="lot.id"
                @click="selectedIndex = index"
                @dblclick="submit"
                :class="{ active: selectedIndex == index }"
              >
                <td>{{ lot.input_date | formatDateNoHours }}</td>
                <td>
                  {{
                    lot.company_id == null
                      ? '자체생산'
                      : findInfoFromId(companys, lot.company_id).name
                  }}
                </td>
                <td>
                  {{
                    findInfoFromId(lot_types, lot.lot_type_id).detail !=
                    'wait process'
                      ? `L${lot.lot_number}`
                      : ''
                  }}
                </td>
                <td>{{ findInfoFromId(products, lot.product_id).name }}</td>
                <td>{{ lot.quantity | makeComma }}</td>
                <td>{{ findInfoFromId(lot_types, lot.lot_type_id).name }}</td>
                <td>{{ lot.emergency_yn == true ? 'Y' : 'N' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal_footer">
        <button class="btn_sub2" @click="submit">
          선택
        </button>
      </div>
    </div>
    <div class="modal_layer" @click="$emit('onclose')"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import FetchMixin from '@/mixins/fetch';
import { yyyymmdd } from '@/utils/func';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
export default {
  mixins: [SpinnerMixin, ModalMixin, FetchMixin, DRAG_MODAL_MIXIN],
  props: {
    filterWaiting: {
      type: Boolean,
    },
    prop_startDate: {
      type: String,
    },
    prop_endDate: {
      type: String,
    },
  },
  data() {
    return {
      error_text: '',
      selectedIndex: -1,
      search_value: '',

      start_date: null,
      end_date: null,
    };
  },
  computed: {
    ...mapGetters({
      lots_src: 'getLot',
      companys: 'getCompany',
      products: 'getProduct',
      lot_types: 'getLotType',
    }),
    lots() {
      if (this.filterWaiting != undefined && this.filterWaiting == true) {
        return this.lots_src
          .filter(
            x =>
              x.lot_type_id !=
              this.lot_types.find(y => y.detail == 'wait process').id,
          )
          .sort((a, b) => new Date(b.input_date) - new Date(a.input_date))
          .filter(
            x =>
              new Date(x.input_date) <= new Date(this.end_date) &&
              new Date(x.input_date) >= new Date(this.start_date),
          );
      } else {
        const lotList = this.lodash.clonedeep(this.lots_src);
        return lotList
          .sort((a, b) =>
            a.emergency_yn === b.emergency_yn
              ? 0
              : a.emergency_yn
              ? -1
              : 1 && new Date(b.input_date) - new Date(a.input_date),
          )
          .filter(
            x =>
              new Date(x.input_date) <= new Date(this.end_date) &&
              new Date(x.input_date) >= new Date(this.start_date),
          );
      }
    },
  },
  methods: {
    changeStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '거래기간 오류',
            `거래기간 최후날짜(${this.end_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.start_date = e.target.value;
        }
        this.FETCH_LOT_DAY();
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        e.target.value = yyyymmdd(new Date());
        this.FETCH_LOT_DAY();
      }
    },
    changeEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '거래기간 오류',
            `거래기간 최초날짜(${this.start_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.end_date = e.target.value;
        }
        this.FETCH_LOT_DAY();
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        e.target.value = yyyymmdd(new Date());
        this.FETCH_LOT_DAY();
      }
    },
    submit() {
      this.$emit(
        'onselect',
        this.lodash.clonedeep(this.lots[this.selectedIndex]),
      );
    },
    FETCH_LOT_DAY() {
      this.showSpinner();
      this.$store
        .dispatch('FETCH_LOT_DAY', {
          start: this.start_date,
          end: this.end_date,
        })
        .then(response => {
          console.log(response);
        })
        .catch(() => {
          this.openOneButtonModal(
            '조회 중 오류',
            '작업지시 정보를 불러오는 중 오류가 발생했습니다.',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },
  async created() {
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.lot_types.length < 1) {
      await this.FETCH('FETCH_LOT_TYPE', '작업상태');
    }

    if (this.prop_startDate != undefined) {
      this.start_date = this.lodash.clonedeep(this.prop_startDate);
      this.end_date = this.lodash.clonedeep(this.prop_endDate);
    } else {
      const date = new Date();
      this.end_date = yyyymmdd(date);
      const date2 = new Date(date.setDate(date.getDate() - 7));
      this.start_date = yyyymmdd(date2);
    }

    await this.FETCH_LOT_DAY();
  },
};
</script>

<style lang="scss" scoped></style>
