<template>
  <div class="modalPopup package_modal">
    <div class="modal_header">
      <h3 class="title">
        자재 반환
      </h3>
      <button
        type="button"
        class="modal_close"
        @click="$emit('onClose')"
      ></button>
    </div>

    <div class="modal_body">
      <div class="modal_search_opt">
        <div class="input_text">
          <label>제품명</label>
          <input
            type="text"
            readonly
            :value="findInfoFromId(products, target_lot.product_id).name"
          />
        </div>
        <div class="input_text">
          <label>규격</label>
          <input
            type="text"
            readonly
            :value="findInfoFromId(products, target_lot.product_id).standard"
          />
        </div>
        <div class="input_text">
          <label>지시량</label>
          <input
            type="text"
            readonly
            :value="$makeComma(target_lot.quantity)"
          />
          <label>{{
            findInfoFromId(
              units,
              findInfoFromId(products, target_lot.product_id).stock_unit_id,
            ).name
          }}</label>
        </div>
        <h5>투입 자재 리스트</h5>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <colgroup>
            <col v-for="(n, index) in 7" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>식별코드</th>
              <th>원자재명</th>
              <th>규격</th>
              <th>BOM</th>
              <th>투입량</th>
              <th>반환량</th>
              <th style="font-size : 12px" v-if="remain_expect_list.length > 0">
                합산반환량 <br />/ 예상반환량
              </th>
              <th>실투입량</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in resource_lists" :key="item.id">
              <td>{{ item.ident_value }}</td>
              <td>{{ findInfoFromId(materials, item.material_id).name }}</td>
              <td>
                {{ findInfoFromId(materials, item.material_id).standard }}
              </td>
              <td>
                {{
                  `${findBomResource(item).quantity} ${
                    findBomResource(item).percent_yn ? '%' : ''
                  }`
                }}
              </td>
              <!-- <td>
                {{
                  `${$makeComma(
                    calNeedQuantity(
                      findBomResource(item),
                      1,
                      target_lot.quantity,
                    ),
                  )} (${
                    findInfoFromId(
                      units,
                      findInfoFromId(materials, item.material_id).using_unit_id,
                    ).name
                  })`
                }}
              </td> -->
              <td>
                {{
                  `${$makeComma(
                    $decimalDiv(item.quantity, getCvrRatio(item.material_id)),
                  )} (${
                    findInfoFromId(
                      units,
                      findInfoFromId(materials, item.material_id).using_unit_id,
                    ).name
                  })`
                }}
              </td>

              <td>
                <input
                  type="text"
                  :value="$makeComma(item.return_quantity)"
                  inputmode="decimal"
                  @input="
                    $inputNumber(
                      $event,
                      item,
                      'return_quantity',
                      $decimalDiv(item.quantity, getCvrRatio(item.material_id)),
                      getStock(item),
                      true,
                    )
                  "
                />
              </td>
              <td
                v-if="
                  findResourceExpectedRemainQuantity(item.material_id) != null
                "
              >
                {{
                  $makeComma(
                    findResourceExpectedRemainQuantity(item.material_id)
                      .sum_return_quantity,
                  )
                }}
                <p
                  :style="
                    findResourceExpectedRemainQuantity(item.material_id)
                      .expect_quantity != 0
                      ? 'color : red; font-size : 12px'
                      : 'font-size : 12px'
                  "
                >
                  /
                  {{
                    $makeComma(
                      findResourceExpectedRemainQuantity(item.material_id)
                        .remain_quantity,
                    )
                  }}
                </p>
              </td>
              <td class="text_right">
                {{
                  `${$makeComma(
                    $decimalSub(
                      $decimalDiv(item.quantity, getCvrRatio(item.material_id)),
                      $makeNumber(
                        item.return_quantity == '-' ? 0 : item.return_quantity,
                      ),
                    ),
                  )} (${
                    findInfoFromId(
                      units,
                      findInfoFromId(materials, item.material_id).using_unit_id,
                    ).name
                  })`
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal_footer">
      <div class="warning">
        {{ '' }}
      </div>
      <button type="button" class="btn_sub2" @click="submitForm()">
        완료
      </button>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import MODAL_MIXIN from '@/mixins/modal';
import { mapGetters } from 'vuex';
export default {
  mixins: [FETCH_MIXIN, MODAL_MIXIN],
  data() {
    return {
      resource_lists: [],
    };
  },
  props: {
    target_lot: {
      type: Object,
      require: true,
    },
    remain_materials: {
      require: false,
    },
  },
  computed: {
    ...mapGetters({
      products: 'getProduct',
      materials: 'getMaterial',
      process: 'getProcess',
      units: 'getUnitCodes',
      unitConversions: 'getUnitConversion',
      material_groups: 'getMaterialGroup',
      material_control: 'getMaterialControl',
    }),
    remain_expect_list() {
      if (this.remain_materials != null && this.remain_materials.length > 0) {
        const remain_expect_list = this.lodash
          .clonedeep(this.remain_materials)
          .map(x => {
            // x.remain_quantity = this.$decimalDiv(
            //   x.remain_quantity,
            //   this.getCvrRatio(x.material_id),
            // );

            // x.remain_quantity = this.getCvrRatio(x.material_id);
            const foundMaterials = this.resource_lists.filter(
              y => y.material_id == x.material_id,
            );
            let sumReturnQuantity = 0;
            if (foundMaterials.length > 0) {
              sumReturnQuantity = foundMaterials
                .map(y => this.$makeNumber(y.return_quantity))
                .reduce((a, b) => this.$decimalAdd(a, b), 0);
            }
            x.expect_quantity = this.$decimalSub(
              x.remain_quantity,
              sumReturnQuantity,
            );
            x.sum_return_quantity = sumReturnQuantity;
            return x;
          });
        return remain_expect_list;
      } else {
        return [];
      }
    },
  },
  methods: {
    submitForm() {
      const payload = this.lodash
        .clonedeep(this.resource_lists)
        .filter(x => this.$makeNumber(x.return_quantity) != 0);
      if (payload.length > 0) {
        this.$emit('onSelect', payload);
      } else {
        this.$emit('onClose');
      }
    },
    findResourceExpectedRemainQuantity(material_id) {
      const foundRemain = this.remain_expect_list.find(
        x => x.material_id == material_id,
      );
      return foundRemain != undefined ? foundRemain : null;
    },
    getCvrRatio(material_id) {
      const material = this.findInfoFromId(this.materials, material_id);
      const cvr_ratio = this.unitConversions.find(
        x =>
          x.in_unit_id == material.incoming_unit_id &&
          x.out_unit_id == material.using_unit_id,
      );
      if (cvr_ratio != undefined) {
        return cvr_ratio.cvr_ratio;
      } else return 1;
    },
    getStock(resource) {
      let rv = 0;
      if (resource != undefined && resource.mio != null) {
        const parseIdent = resource.ident_value.slice(9);
        const purchase_material_id = Number(parseIdent);
        let control = this.material_control.filter(
          x => x.purchase_material_id == purchase_material_id,
        );
        control = control.find((x, index) => {
          if (x.quantity > 0) {
            return x;
          } else if (control.length - 1 == index) {
            return x;
          }
        });
        if (control != undefined) {
          rv = this.$decimalMul(
            -1,
            this.$decimalDiv(
              control.quantity,
              this.getCvrRatio(control.material_id),
            ),
          );
        }
      }

      return rv;
    },
    calNeedQuantity(resource) {
      // console.log('resource.percent_yn', resource);
      if (resource.percent_yn) {
        let resourceInfo = null;
        let cvr_ratio = null;
        const productInfo = this.findInfoFromId(
          this.products,
          this.target_lot.product_id,
        );
        console.log('productInfo', productInfo);
        resourceInfo = this.findInfoFromId(
          this.materials,
          resource.material_id,
        );
        console.log('resourceInfo', resourceInfo);
        console.log('test');
        cvr_ratio = this.unitConversions.find(
          x =>
            x.in_unit_id == resourceInfo.using_unit_id &&
            x.out_unit_id == productInfo.stock_unit_id,
        );
        console.log(cvr_ratio);

        if (cvr_ratio == undefined) {
          cvr_ratio = 1;
        } else {
          cvr_ratio = cvr_ratio.cvr_ratio;
        }

        return this.$decimalMul(
          this.$makeNumber(this.target_lot.quantity),
          this.$decimalMul(this.$decimalDiv(resource.quantity, 100), cvr_ratio),
        );
      } else {
        return this.$makeNumber(
          this.$decimalMul(
            this.$makeNumber(resource.quantity),
            this.$makeNumber(this.target_lot.quantity),
          ),
        );
      }
    },
    findBomResource(item) {
      const bomResource = this.target_lot.bom_info.bom_process
        .find(x => x.process_id == item.process_id)
        .bom_resource.find(x => x.material_id == item.material_id);
      if (bomResource != undefined) return bomResource;
      else {
        const bomResources = this.target_lot.bom_info.bom_process.find(
          x => x.process_id == item.process_id,
        ).bom_resource;

        const group_materials = this.material_groups.filter(
          x => x.member_material_id == item.material_id,
        );

        console.log('bomResources ', bomResources);
        console.log('group_materials ', group_materials);
        const findParents = bomResources.find(x =>
          group_materials.map(y => y.group_material_id).includes(x.material_id),
        );
        if (findParents == undefined) {
          return { quantity: 0, percent_yn: false };
        } else {
          return findParents;
        }
      }
    },
    FETCH_LOT_TRACKING_RESOURCE_TO_PRODUCTION() {
      this.$store
        .dispatch(
          'FETCH_LOT_TRACKING_RESOURCE_TO_PRODUCTION',
          this.target_lot.id,
        )
        .then(data => {
          this.resource_lists = data
            .filter(x => x.material_id != null)
            .map(x => {
              x.return_quantity = 0;
              return x;
            });
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '투입 자재 리스트를 로드하는 중 오류발생',
          );
        });
    },
  },
  async created() {
    await this.FETCH_LOT_TRACKING_RESOURCE_TO_PRODUCTION();
    if (this.material_groups.length < 1) {
      await this.FETCH('FETCH_MATERIAL_GROUP', '자재그룹');
    }
    await this.FETCH('FETCH_MATERIAL_CONTROL', '자재현재고');
  },
};
</script>

<style lang="scss" scoped></style>
